<template>
  <div class="locker_setting_list sub_new_style01 sub_ui_box1">
    <Search
      codeKey="search_task_type"
      :searchObj="searchType.obj"
      :useKeywordType="true"
      @change-search-type="$_changeSearchType"
      @onSearchClick="$_searchData($event, 'search_task_type')"
    />
    <esp-dx-data-grid :data-grid="dataGrid" ref="taskGrid" />

    <DxPopup
      v-if="modal.isOpened"
      :show-title="true"
      :title="modal.initData ? modal.initData.title : null"
      :width="modal.initData ? modal.initData.width : null"
      :height="modal.initData ? modal.initData.height : null"
      :drag-enabled="true"
      :resize-enabled="true"
      :show-close-button="true"
      :close-on-outside-click="false"
      :visible="modal.isOpened"
      @hiding="isOpenModal(false)"
    >
      <template #content>
        <div>
          <DxScrollView
            v-if="modal.isOpened"
            id="scrollview"
            ref="scrollViewWidget"
            :scroll-by-content="scroll.scrollByContent"
            :scroll-by-thumb="scroll.scrollByThumb"
            :show-scrollbar="scroll.showScrollbar"
            :bounce-enabled="scroll.pullDown"
            :height="550"
          >
            <component
              ref="modalRef"
              v-if="modal.sendData"
              :is="modal.currentComponent"
              :modalData="modal.sendData"
              :isModal="modal.isModal"
            />
          </DxScrollView>
        </div>
      </template>
      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :options="{
          elementAttr: {
            class: 'default filled txt_S medium',
          },
          text: modal.initData.buttons.save.text,
          width: '120',
          height: '40',
          onClick: () => {
            this.onSaveModal();
          },
        }"
      />

      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :options="{
          elementAttr: {
            class: 'white filled txt_S medium',
          },
          text: modal.initData.buttons.cancel.text,
          width: '120',
          height: '40',
          onClick: () => {
            isOpenModal(false);
          },
        }"
      />
    </DxPopup>
  </div>
</template>

<script>
  import Search from '@/components/common/search.vue';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { DxCustomRule, DxRequiredRule, DxValidator } from 'devextreme-vue/validator';
  import { DxTextBox } from 'devextreme-vue/text-box';
  import { isSuccess } from '@/plugins/common-lib';
  import ModalSubTaskChange from './modal-sub-task-change.vue';
  import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
  import { DxScrollView } from 'devextreme-vue/scroll-view';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  export default {
    components: {
      EspDxDataGrid,
      DxScrollView,
      DxToolbarItem,
      DxPopup,
      DxTextBox,
      DxCustomRule,
      DxRequiredRule,
      DxValidator,
      DxSelectBox,
      Search,
      ModalSubTaskChange,
    },
    data() {
      return {
        scroll: {
          scrollByContent: false,
          scrollByThumb: true,
          showScrollbar: 'onHover', //[onScroll, onHover, always, never]
          pullDown: false,
        },
        modal: {
          isOpened: false,
          currentComponent: null,
          initData: {
            buttons: {
              save: '',
              cancel: '',
            },
          },
          sendData: null,
        },
        config: {
          pageSetting: {
            //pageData pageSetting 관련
            config: {},
          },
        },
        stylingMode: 'outlined', //outlined, underlined, filled
        searchType: {
          obj: {},
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          customTypes: {},
          paramsData: null,
        },
        dataGrid: {
          callApi: 'CALL_EUC_API',
          refName: 'taskGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          // width:'200',     // 주석처리시 100%
          height: '650', // 주석처리시 100%
          apiActionNm: {
            update: 'EUC_TASK_UPDATE',
            loading: true,
          },
          customEvent: {
            initNewRow: true,
          },
          showActionButtons: {
            customButtons: [
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '삭제',
                  elementAttr: { class: 'btn_XS white light_filled trash' },
                  width: 60,
                  height: 30,
                  onClick: () => {
                    this.onDeleteData();
                  },
                },
                location: 'before',
              },
            ],
          },
          isDuplicateConfigKey: false, //설정키 중복 체크
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: true,
            sorting: true,
            grouping: true,
            paging: true,
          },
          paging: {
            enabled: true,
            pageSize: 100,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: false,
          },
          headerFilter: {
            visible: false,
          },
          editing: {
            allowUpdating: true, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: true, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '작업명',
              dataField: 'taskNm',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '설명',
              dataField: 'description',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '파일명',
              dataField: 'queryFile',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowExporing: true,
              fixed: false,
            },
            {
              caption: 'Interval',
              dataField: 'interval',
              width: 120,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '작업 수',
              dataField: 'subTaskCnt',
              width: 110,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              cellTemplate: (container, options) => {
                if (options.value) {
                  const aTag = document.createElement('a');
                  aTag.innerText = options.value;
                  aTag.addEventListener('click', () => {
                    this.onSubTaskChange(options);
                  });
                  container.append(aTag);
                }
              },
            },
            {
              caption: '순서',
              dataField: 'taskOrd',
              width: 90,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '수정자',
              dataField: 'editId',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '수정일',
              dataField: 'editDt',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              calculateCellValue: this.formatDt,
            },
          ],
        },
      };
    },
    computed: {},
    methods: {
      async onSaveModal() {
        const payload = {
          actionname: 'EUC_TASK_UPDATE',
          data: [this.$refs.modalRef.task],
          loading: true,
        };
        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          this.$_Toast(this.$_msgContents('CMN_SUC_UPDATE'), { icon: 'success' });
          this.isOpenModal(false);
          this.$refs.taskGrid.refreshData();
        }
      },
      async onDeleteData() {
        const selectedRowsData = this.$refs.taskGrid.selectedRowsData;
        if (selectedRowsData?.length) {
          if (
            await this.$_Confirm(
              this.$_msgContents('COMMON.MESSAGE.CMN_CFM_DELETE_SELECTED', { defaultValue: '선택한 데이터를 삭제하시겠습니까?' }),
            )
          ) {
            const ids = selectedRowsData.map(row => {
              return { id: row.id };
            });

            const payload = {
              actionname: 'EUC_TASK_DELETE',
              data: { data: ids },
              loading: true,
            };

            const res = await this.CALL_EUC_API(payload);
            if (isSuccess(res)) {
              this.$_Toast(this.$_msgContents('CMN_SUC_DELETE'), { icon: 'success' });
              this.$refs.taskGrid.refreshData();
            }
          }
        } else {
          this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }), {
            icon: 'warning',
          });
        }
      },
      onSubTaskChange(options) {
        this.onOpenModal(
          'ModalSubTaskChange',
          {
            title: `작업 상세 페이지`,
            //buttons: null,
            width: '1400',
            height: '750',
            buttons: {
              save: { text: '저장' },
              cancel: { text: '닫기' },
            },
          },
          options,
        );
      },
      /** @description: 팝업 창 열때 이벤트 */
      onOpenModal(componentNm, componentInitData, sendData) {
        this.modal.currentComponent = componentNm; //set dynamic component name in modal body slot
        this.modal.initData = componentInitData; //set init modal templet
        this.modal.sendData = sendData;
        this.isOpenModal(true);
      },
      /** @description: 팝업이 열렸는지 체크하는 메서드(true: 열림/false: 닫힘) */
      isOpenModal(data) {
        this.modal.isOpened = data;
        if (!data) {
          this.modal.currentComponent = null;
          this.modal.initData = {};
          this.modal.sendData = null;
        }
      },
      async selectDataList(sort = '+taskOrd') {
        this.dataGrid.dataSource = new CustomStore({
          key: 'id',
          load: async loadOptions => {
            let params = this.$_getDxDataGridParam(loadOptions);
            if (!params.sort) {
              params.sort = sort;
            }

            params = { ...params, ...this.searchType.paramsData };

            const payload = {
              actionname: 'EUC_TASK_LIST_ALL',
              data: params,
              loading: false,
            };

            const rtnData = {
              data: [],
              totalCount: 0,
            };

            const res = await this.CALL_EUC_API(payload);
            if (isSuccess(res)) {
              rtnData.data = res.data.data;
              rtnData.totalCount = res.data.header.totalCount;
            }
            return rtnData;
          },
        });
      },
      /** @description : 날짜 형식 변환 메서드 */
      formatDt(rowData) {
        if (rowData?.editDt) {
          return this.$_commonlib.formatDate(rowData.editDt, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
        }
      },
      /** @description : 라이프사이클 created시 호출되는 메서드 */
      createdData() {},
      /** @description : 라이프사이클 mounted시 호출되는 메서드 */
      mountData() {
        this.selectDataList();
      },
    },
    created() {
      this.createdData();
    },
    mounted() {
      this.mountData();
    },
  };
</script>